import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import { BaseConocimientos } from 'modules/tutoriales/baseConocimientos/baseConocimientos'
class BaseConocimiento extends Component<any, any> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <BaseConocimientos />
        </AAnimate>
      </Layout>
    )
  }
}

export default BaseConocimiento
