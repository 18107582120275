import React, { Component, Fragment } from 'react'
import { ABanner, Go } from 'shared/components/common'
import flechaApp from 'img/vectores/flechaApp.svg'
import { Col, Container, Row } from 'reactstrap'

export class BaseConocimientos extends Component<any> {
  render() {
    return (
      <Fragment>
        <ABanner
          title="Base de conocimientos"
          titleClassName="big-white"
          titleSize={9}
          content={<img src={flechaApp} className="img-fluid" />}
        />
        <Container className="pad ">
          <Row>
            <Col xs={12}>
              <h2 className="big-blue">Tutoriales</h2>
              <br />
              <p> En allAbordo te llevamos de la mano .</p>{' '}
              <ul className="ventajas-app">
                <li>
                  <Go to="/tutorialregistro">
                    Como registrarme desde la app de <strong>AbordoMovil</strong>.
                  </Go>
                </li>
                <li>Pronto más tutoriales....</li>
              </ul>
              <br />
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default BaseConocimientos
